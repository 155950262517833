<template>
  <AppLayout>
    <template v-slot:appContent>
      <div class="franchisee-setting-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
          <h4 class="">Franchisee/List</h4>
        </div>
        <div class="accordion" id="franchiseeAccordion">

          <div class="card">
            <div class="card-header" id="settingFranchiseeCodePrefixHeading">
              <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                      data-target="#settingFranchiseeCodePrefixCollapse" aria-expanded="true" aria-controls="collapseFranchiseeCodePrefix">
                <span class="text-capitalize franchisee-setting-title">Franchisee Code Prefix</span>
              </button>
            </div>
            <div id="settingFranchiseeCodePrefixCollapse" class="collapse" aria-labelledby="settingFranchiseeCodePrefixHeading" data-parent="#franchiseeAccordion">
              <div class="card-body">
                <SettingFranchiseeCodePrefix :franchisee-code-prefix="franchiseeCodePrefix" />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header" id="settingFranchiseeCodeSuffixHeading">
              <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                      data-target="#settingFranchiseeCodeSuffixCollapse" aria-expanded="true" aria-controls="collapseFranchiseeCodeSuffix">
                <span class="text-capitalize franchisee-setting-title">Franchisee Code Suffix</span>
              </button>
            </div>
            <div id="settingFranchiseeCodeSuffixCollapse" class="collapse" aria-labelledby="settingFranchiseeCodeSuffixHeading" data-parent="#franchiseeAccordion">
              <div class="card-body">
                <SettingFranchiseeCodeSuffix :franchisee-code-suffix="franchiseeCodeSuffix" />
              </div>
            </div>
          </div>

        </div>

      </div>
    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import SettingFranchiseeCodePrefix from "@/views/backEnd/settings/franchisee/includes/SettingFranchiseeCodePrefix";
import SettingFranchiseeCodeSuffix from "@/views/backEnd/settings/franchisee/includes/SettingFranchiseeCodeSuffix";

export default {
  name: "SettingsFranchiseeList.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingFranchiseeCodePrefix,
    SettingFranchiseeCodeSuffix,

  },
  data() {
    return {
      getSettingsParams: {
        type: ["franchisee"],
        key: [
          "franchisee_code_prefix",
          "franchisee_code_suffix",
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      franchiseeCodePrefix: 'appSettings/settingFranchiseeCodePrefix',
      franchiseeCodeSuffix: 'appSettings/settingFranchiseeCodeSuffix',
    }),
  },
  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.franchisee-setting-wrapper .accordion .card{
  overflow: visible !important;
}
.franchisee-setting-title{
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
